var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "pt-0 overflow-y-auto qms_settings_page",
    attrs: {
      "fluid": ""
    }
  }, [_c('v-card', {
    staticStyle: {
      "max-width": "1200px"
    },
    attrs: {
      "disabled": _vm.loading,
      "loading": _vm.loading
    }
  }, [_c('v-card-title', {
    staticClass: "primary white--text text-h6"
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-medal-outline")]), _vm._v("Quality Management System ")], 1), _c('v-card-text', [_c('v-list', {
    staticClass: "transparent"
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("Manufacture Steps")]), _c('v-list-item-subtitle', [_vm._v(" Track who completed Manufacture Steps on a per job basis."), _c('br'), _c('span', {
    staticClass: "text-caption font-italic"
  }, [_vm._v("eg. 3D Modelling, Machining, Hand Finishing, Covering")])])], 1), _c('v-list-item-action', [_c('v-btn', {
    staticClass: "primary",
    on: {
      "click": function click() {
        _vm.$refs.steps.open();
      }
    }
  }, [_vm._v("Manage")]), _c('Steps', {
    ref: "steps"
  })], 1)], 1), _c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("Manufacture Partners")]), _c('v-list-item-subtitle', [_vm._v(" Manufacture Partners who may also complete Manufacture Steps."), _c('br'), _c('span', {
    staticClass: "text-caption font-italic"
  }, [_vm._v("eg. 3D Printing Services")])])], 1), _c('v-list-item-action', [_c('Partners', {
    ref: "partners"
  }), _c('v-btn', {
    staticClass: "primary",
    on: {
      "click": function click() {
        _vm.$refs.partners.open();
      }
    }
  }, [_vm._v("Manage")])], 1)], 1), _c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("QR Code Scanner")]), _c('v-list-item-subtitle', [_vm._v(" A test demonstration of the scanner component."), _c('br')])], 1), _c('v-list-item-action', [_c('v-btn', {
    staticClass: "primary",
    on: {
      "click": function click() {
        _vm.$refs.scanner.scan();
      }
    }
  }, [_vm._v("Scan")])], 1)], 1)], 1)], 1)], 1), _c('Scanner', {
    ref: "scanner",
    on: {
      "scan": _vm.onScan
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }