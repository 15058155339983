<template>
  <div>

    <v-dialog :value='show' max-width='400px' scrollable persistent>
      <v-card :disabled='loading'>
        <v-card-title class='primary white--text text-body-1'>
          Manufacture Steps
          <v-spacer />
          <v-btn fab x-small text dark @click='addStep'><v-icon>mdi-plus</v-icon></v-btn>
        </v-card-title>
        <v-card-text style='height: 40vh;' class='px-0'>
          <v-list dense class='' ref='sortable'>
            <v-list-item v-for='step in steps' :key='step.xid' class='' :data-id='step.xid'>
              <v-list-item-avatar class='dragMe my-0'><v-icon>mdi-drag</v-icon></v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{step.title}}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action class='my-0'>
                <v-menu>
                  <template #activator='{on,attrs}'>
                    <v-btn v-bind="attrs" v-on="on" fab x-small text><v-icon>mdi-dots-horizontal</v-icon></v-btn>
                  </template>
                    <v-list dense>
                      <v-list-item link @click='rename(step)'>
                        <v-icon class='primary--text mr-4'>mdi-rename-box</v-icon>Rename
                      </v-list-item>
                      <v-list-item link @click='qrCode(step)'>
                        <v-icon class='primary--text mr-4'>mdi-qrcode</v-icon>Print QR Label
                      </v-list-item>
                      <v-divider />
                      <v-list-item link @click='remove(step)'>
                        <v-icon class='red--text mr-4'>mdi-trash-can</v-icon>Delete
                      </v-list-item>
                    </v-list>
                </v-menu>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer/>
          <v-btn class='primary' @click='show=false' :loading='loading'>Done</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<style lang="scss" scoped>
//@import "@/assets/common";
.dragMe{
  cursor: grab;
}
</style>

<script>
import { mapActions } from 'vuex'
import Sortable from 'sortablejs';
export default {
  name: '',

  components: {
  },

  data: () => ({
    show: false,
    loading: false,
    steps: [],
    sortable: null,
  }),

  computed: {
  },

  methods:{
    ...mapActions("API", ["API"]),
    ...mapActions("UI", ["PROMPT", "CONFIRM"]),
    ...mapActions("PRINTING", ["PRINT"]),

    open(){
      this.refreshList();
      this.show = true;
      this.$nextTick(this.makeSortable);
    },

    refreshList(){
      this.loading = true;
      this.API({ method: 'GET', endpoint: 'qms/steps' })
      .then(steps => { this.steps = steps; })
      .finally(() => { this.loading = false; });
    },

    addStep(){
      this.PROMPT({ title: "Add Step", message: "Step name:" })
      .then(title => {
        if(title === '') return;
        this.API({ method: 'POST', endpoint: 'qms/steps', data: { title } })
        .then(this.refreshList)
      });
    },

    makeSortable(){
      if(this.sortable) return;
      this.sortable = new Sortable(this.$refs.sortable.$el, {
        animation: 250,
        handle: '.dragMe',
        swapThreshold: 1,
        onEnd: this.sort,
      });
    },

    sort(e){
      if(e.newIndex == e.oldIndex) return;
      let sort_order = this.sortable.toArray();
      this.loading = true;
      this.API({ method: 'POST', endpoint: 'qms/steps/sort', data: { sort_order } })
      .finally(() => { this.loading = false; })
    },

    rename(step){
      this.PROMPT({ title: "Rename Step", message: `Rename '${step.title}':`, placeholder: step.title })
      .then(title => {
        if(title === '' || title === step.title) return;
        this.loading = true;
        this.API({ method: 'PATCH', endpoint: `qms/steps/${step.xid}`, data: { title } })
        .finally(this.refreshList);
      });
    },

    remove(step){
      this.CONFIRM({ title: 'Delete Step', message: `Delete '${step.title}'?` })
      .then(() => {
        this.API({ method: 'DELETE', endpoint: `qms/steps/${step.xid}` })
        .finally(this.refreshList);
      });
    },

    qrCode(step){
      this.PRINT({ url: `${window.app.config.api_base_url}/qms/steps/${step.xid}/qr`, type: 'Job Label' });
    }



  },

  mounted() {
  },

};
</script>
