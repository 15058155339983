<template>
  <v-container fluid class='pt-0 overflow-y-auto qms_settings_page'>

    <v-card style='max-width:1200px;' :disabled='loading' :loading='loading'>
      <v-card-title class='primary white--text text-h6'>
        <v-icon class='mr-2' dark>mdi-medal-outline</v-icon>Quality Management System
      </v-card-title>
      <v-card-text>
        <v-list class='transparent'>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Manufacture Steps</v-list-item-title>
              <v-list-item-subtitle>
                Track who completed Manufacture Steps on a per job basis.<br />
                <span class='text-caption font-italic'>eg. 3D Modelling, Machining, Hand Finishing, Covering</span>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn class='primary' @click='()=>{$refs.steps.open()}'>Manage</v-btn>
              <Steps ref='steps' />
            </v-list-item-action>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Manufacture Partners</v-list-item-title>
              <v-list-item-subtitle>
                Manufacture Partners who may also complete Manufacture Steps.<br />
                <span class='text-caption font-italic'>eg. 3D Printing Services</span>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <Partners ref='partners' />
              <v-btn class='primary' @click='()=>{$refs.partners.open()}'>Manage</v-btn>
            </v-list-item-action>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>QR Code Scanner</v-list-item-title>
              <v-list-item-subtitle>
                A test demonstration of the scanner component.<br />
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn class='primary' @click='()=>{$refs.scanner.scan()}'>Scan</v-btn>
            </v-list-item-action>
          </v-list-item>

        </v-list>
      </v-card-text>
    </v-card>

    <Scanner ref='scanner' @scan='onScan' />

  </v-container>
</template>

<style lang="scss">
//@import "@/assets/common";
</style>

<script>
import Scanner from '@/components/BarcodeScannerPopup'
import Steps from '@/components/QMS/Settings/Steps'
import Partners from '@/components/QMS/Settings/Partners'
import { mapActions } from 'vuex'
export default {
  name: 'Template',

  components: {
    Scanner, Steps, Partners
  },

  data: () => ({
    loading: false,
    config: {
      manufacture_partners: [],
    },
  }),

  computed: {
  },

  methods:{
    ...mapActions("UI", ["ALERT"]),
    onScan(code){
      this.ALERT({ title: "QR Scan", message: code });
    },
  },

  mounted() {
  },

};
</script>
