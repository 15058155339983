var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-dialog', {
    attrs: {
      "value": _vm.show,
      "max-width": "400px",
      "scrollable": "",
      "persistent": ""
    }
  }, [_c('v-card', {
    attrs: {
      "disabled": _vm.loading
    }
  }, [_c('v-card-title', {
    staticClass: "primary white--text text-body-1"
  }, [_vm._v(" Manufacture Steps "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "fab": "",
      "x-small": "",
      "text": "",
      "dark": ""
    },
    on: {
      "click": _vm.addStep
    }
  }, [_c('v-icon', [_vm._v("mdi-plus")])], 1)], 1), _c('v-card-text', {
    staticClass: "px-0",
    staticStyle: {
      "height": "40vh"
    }
  }, [_c('v-list', {
    ref: "sortable",
    attrs: {
      "dense": ""
    }
  }, _vm._l(_vm.steps, function (step) {
    return _c('v-list-item', {
      key: step.xid,
      attrs: {
        "data-id": step.xid
      }
    }, [_c('v-list-item-avatar', {
      staticClass: "dragMe my-0"
    }, [_c('v-icon', [_vm._v("mdi-drag")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(step.title))])], 1), _c('v-list-item-action', {
      staticClass: "my-0"
    }, [_c('v-menu', {
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref) {
          var on = _ref.on,
            attrs = _ref.attrs;
          return [_c('v-btn', _vm._g(_vm._b({
            attrs: {
              "fab": "",
              "x-small": "",
              "text": ""
            }
          }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-dots-horizontal")])], 1)];
        }
      }], null, true)
    }, [_c('v-list', {
      attrs: {
        "dense": ""
      }
    }, [_c('v-list-item', {
      attrs: {
        "link": ""
      },
      on: {
        "click": function click($event) {
          return _vm.rename(step);
        }
      }
    }, [_c('v-icon', {
      staticClass: "primary--text mr-4"
    }, [_vm._v("mdi-rename-box")]), _vm._v("Rename ")], 1), _c('v-list-item', {
      attrs: {
        "link": ""
      },
      on: {
        "click": function click($event) {
          return _vm.qrCode(step);
        }
      }
    }, [_c('v-icon', {
      staticClass: "primary--text mr-4"
    }, [_vm._v("mdi-qrcode")]), _vm._v("Print QR Label ")], 1), _c('v-divider'), _c('v-list-item', {
      attrs: {
        "link": ""
      },
      on: {
        "click": function click($event) {
          return _vm.remove(step);
        }
      }
    }, [_c('v-icon', {
      staticClass: "red--text mr-4"
    }, [_vm._v("mdi-trash-can")]), _vm._v("Delete ")], 1)], 1)], 1)], 1)], 1);
  }), 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "primary",
    attrs: {
      "loading": _vm.loading
    },
    on: {
      "click": function click($event) {
        _vm.show = false;
      }
    }
  }, [_vm._v("Done")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }